import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "tabPane"];

  initialize() {
    const tabPanes = this.tabPaneTargets.map((el) => el.value);
    this.handleInitialTab();
  }

  connect() {
    window.addEventListener("popstate", (event) => {
      this.handleInitialTab();
    });
  }

  handleInitialTab = () => {
    const tabPanes = this.tabPaneTargets.map((el) => el.value);
    const urlSearchParams = window.location.search;
    const currentParam = new URLSearchParams(urlSearchParams).get("tab");
    tabPanes.map((el) => {
      document.querySelector(`#${el}`).classList.add("hidden");
      this.toggleTabOff(`#${el}-btn`);
    });

    if (currentParam) {
      this.toggleTabOn(`#${currentParam}-btn`);
      this.appendQueryParam(currentParam);
      document.querySelector(`#${currentParam}`).classList.remove("hidden");
    } else {
      this.toggleTabOn(`#${tabPanes[0]}-btn`);
      this.appendQueryParam(tabPanes[0]);
      document.querySelector(`#${tabPanes[0]}`).classList.remove("hidden");
    }
  };

  selectTab(event) {
    const btnId = event.srcElement.id;
    const paneId = btnId.substring(0, btnId.length - 4);
    const tabPanes = this.tabPaneTargets.map((el) => el.value);

    this.appendQueryParam(paneId);

    tabPanes.map((el, idx) => {
      if (el === paneId) {
        document.querySelector(`#${el}`).classList.remove("hidden");
        this.toggleTabOn(`#${el}-btn`);
      } else {
        document.querySelector(`#${el}`).classList.add("hidden");
        this.toggleTabOff(`#${el}-btn`);
      }
    });
  }

  appendQueryParam(param) {
    const urlSearchParams = window.location.search;
    const urlParam = new URLSearchParams(urlSearchParams);
    const isCurrentParam = urlParam.get("tab") === param;
    urlParam.delete("tab");

    if (!isCurrentParam) {
      const newUrl = `${window.location.protocol
      }//${
        window.location.host
      }${window.location.pathname
      }${urlSearchParams ? `?${urlParam}&` : "?"}tab=${param}`;

      window.history.pushState({ path: newUrl }, "", newUrl);
    }
  }

  toggleTabOn(tabId) {
    document.querySelector(tabId).classList.add("border-indigo-500");
    document.querySelector(tabId).classList.add("text-indigo-600");
    document.querySelector(tabId).classList.add("font-medium");
  }

  toggleTabOff(tabId) {
    document.querySelector(tabId).classList.remove("border-indigo-500");
    document.querySelector(tabId).classList.remove("text-indigo-600");
    document.querySelector(tabId).classList.remove("font-medium");
  }
}
