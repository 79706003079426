import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const filter = document.querySelector("#turbulence");
    const rad = Math.PI / 180;
    let frames = 0;
    let bfx;
    let bfy;
    let bf;
    function freqAnimation() {
      bfx = 0.005;
      bfy = 0.005;
      frames += .8;
      bfx += 0.002 * Math.cos(frames * rad);
      bfy += 0.002 * Math.sin(frames * rad);

      bf = bfx.toString() + ' ' + bfy.toString();
      filter.setAttributeNS(null, 'baseFrequency', bf);
      window.requestAnimationFrame(freqAnimation);
    }

    window.requestAnimationFrame(freqAnimation);
  }
}
