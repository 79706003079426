import { Controller } from "@hotwired/stimulus";

const CHILD_SEAT_MODAL_CONTENT_ID = "child_seats_modal_content";
const CHILD_SEAT_MODAL_ID = "child_seats_modal";
const CHILD_SEAT_MODAL_TOOGLE = "child_seats_modal_toogle";

export default class extends Controller {
  static targets = ["modalId", "container"];

  static values = {
    targetId: String,
    contentWrapperId: String,
  };

  toggleModal = () => {
    if (this.hasModalIdTarget) {
      const modalElement = document.querySelector(`#${this.modalIdTarget.value}`);
      modalElement.classList.toggle("hidden");
    } else {
      this.element.classList.toggle("hidden");
    }
  };

  closeBackground(e) {
    if (this.hasContainerTarget && this.containerTarget.contains(e.target)) {
      return;
    }

    const childSeatsModal = document.querySelector(
      `#${CHILD_SEAT_MODAL_CONTENT_ID}`,
    );
    const isModalVisible = !document
      .querySelector(`#${CHILD_SEAT_MODAL_ID}`)
      ?.classList.contains("hidden");
    const isToogleModalBtn = e.target.id === CHILD_SEAT_MODAL_TOOGLE;

    if (
      isModalVisible
      && childSeatsModal
      && !childSeatsModal.contains(e.target)
      && !isToogleModalBtn
    ) {
      this.toggleModal();
    } else {
      this.close();
    }
  }

  close() {
    this.element.remove();
    if (this.targetIdValue) {
      this.modalTurboFrame.src = null;
    }
  }

  closeWithBackgroundTap(e) {
    const contentWrapper = document.querySelector(
      `#${this.contentWrapperIdValue}`,
    );
    if (!contentWrapper.contains(e.target)) {
      this.close();
    }
  }

  get modalTurboFrame() {
    return document.querySelector(`turbo-frame[id='${this.targetIdValue}']`);
  }
}
