/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { addHours, subHours, format } from "date-fns";
import { customToolTip, lineAnnotations } from "../chart";
import { DATA_POINT_DATE_TIME_FORMAT } from "../chart/constants";

const handleScreenSizeBarsToShow = () => {
  const screenWidth = window.innerWidth;

  if (screenWidth < 400) return 5;
  if (screenWidth < 500) return 7;
  if (screenWidth < 600) return 10;
  if (screenWidth < 700) return 11;
  if (screenWidth < 800) return 12;
  if (screenWidth < 1000) return 15;

  return 16;
};

const getBounds = () => {
  const barPointsToShow = handleScreenSizeBarsToShow();

  const currentDate = new Date();
  const currentHour = format(
    subHours(currentDate, 1),
    DATA_POINT_DATE_TIME_FORMAT,
  );

  return {
    min: currentHour,
    max: format(
      addHours(currentDate, barPointsToShow),
      DATA_POINT_DATE_TIME_FORMAT,
    ),
  };
};

const getPanLimits = (dataSetBounds) => ({
  // requires Unix timestamp in milliseconds
  x: {
    min: new Date(dataSetBounds.firstPoint).valueOf(),
    max: new Date(dataSetBounds.lastPoint).valueOf(),
  },
});

export default ({
  dataPoints, isMultipleDataSets, dataSetBounds, highestDataPoint,
}) => ({
  scales: {
    x: {
      type: "time",
      time: {
        displayFormats: {
          hour: "HH:mm",
        },
      },
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        color: "#8B80C7",
      },
      ...getBounds(),
    },
    y: {
      type: "linear",
      min: 0,
      suggestedMax: highestDataPoint + (highestDataPoint / 2), // Add 50% of the highest point to create some space for the annotation and tooltip placement
      stacked: true,
      display: false,
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
      grace: 8,
    },
  },
  plugins: {
    legend: false,
    title: false,
    tooltip: {
      enabled: false,
      external: customToolTip,
    },
    annotation: lineAnnotations({
      dataPoints,
      isMultipleDataSets,
    }),
    decimation: {
      enabled: true,
    },
    zoom: {
      pan: {
        enabled: true,
        mode: "x",
        onPan({ chart }) {
          const { tooltip } = chart;
          // Removing active tooltip on pan event(swipe, scroll)
          if (tooltip.getActiveElements().length > 0) {
            tooltip.setActiveElements([], { x: 0, y: 0 });
          }
        },
      },
      limits: getPanLimits(dataSetBounds),
    },
  },
  interaction: {
    mode: "nearest",
    axis: "x",
    intersect: false,
  },
  events: ["click"], // this prevents showing tooltip on mouse hover
  onResize(chart) {
    if (chart) {
      chart.options.scales.x = {
        ...chart.options.scales.x,
        ...getBounds(),
      };

      chart?.tooltip?.setActiveElements([], { x: 0, y: 0 });
      chart.update("none");
    }
  },
});
