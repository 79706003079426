import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { currentStep: Number };

  initialize() {}

  clearForm() {
    this.element.reset();
  }

  getStarted() {
    this.handleNextScreen("#form-wizard-note", "#form-wizard-signup");
  }

  backToNote() {
    this.handlePreviousScreen("#form-wizard-signup", "#form-wizard-note");
  }

  showPreview() {
    this.handleNextScreen("#form-wizard-signup", "#form-wizard-preview");
  }

  backToSignup() {
    this.handlePreviousScreen("#form-wizard-preview", "#form-wizard-signup");
  }

  showContract() {
    this.handleNextScreen("#form-wizard-preview", "#form-wizard-contract");
  }

  backToPreview() {
    this.handlePreviousScreen("#form-wizard-contract", "#form-wizard-preview");
  }

  showInvoice() {
    this.handleNextScreen("#form-wizard-contract", "#form-wizard-invoice");
  }

  backToContract() {
    this.handlePreviousScreen("#form-wizard-invoice", "#form-wizard-contract");
  }

  completeSignupWizard() {}

  handleNextScreen(currentScreen, nextScreen) {
    this.addClassById(currentScreen, "opacity-0");
    setTimeout(() => {
      this.addClassById(currentScreen, "hidden");
      this.removeClassById(nextScreen, "hidden");
      setTimeout(() => {
        this.removeClassById(nextScreen, "translate-x-full");
        this.removeClassById(nextScreen, "opacity-0");
      }, 100);
    }, 100);
  }

  handlePreviousScreen(currentScreen, previousScreen) {
    this.addClassById(currentScreen, "translate-x-full");
    this.addClassById(currentScreen, "opacity-0");
    setTimeout(() => {
      this.removeClassById(previousScreen, "hidden");
      this.addClassById(currentScreen, "hidden");
      setTimeout(() => {
        this.removeClassById(previousScreen, "opacity-0");
      }, 100);
    }, 100);
  }

  removeClassById(objectId, className) {
    document.querySelector(objectId).classList.remove(className);
  }

  addClassById(objectId, className) {
    document.querySelector(objectId).classList.add(className);
  }
}
