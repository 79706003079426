import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce"

export default class extends Controller {
  static values = { autoSubmit: { type: Boolean, default: false } };

  initialize() {
    if (this.autoSubmitValue) {
      this.autoSubmit = debounce(this.autoSubmit, 500).bind(this);
    }
  }

  disable() {
    this.element.querySelectorAll("[type=submit]").forEach((element) => {
      element.dataset["previousValue"] = element.value;
      element.value = element.dataset["disableWith"];
    });
  }

  reset() {
    this.element.querySelectorAll("[type=submit]").forEach((element) => {
      element.value = element.dataset["previousValue"];
      element.disabled = false;
    });
  }

  autoSubmit() {
    this.element.requestSubmit();
  }
}
