import { Controller } from "@hotwired/stimulus";

const TOOGLE_BTN_ELEMENTS_IDS = [
  "filter-btn-toogle",
  "filter-btn-toogle-text",
  "filter-btn-toogle-img",
];
const TOOGLE_CLASSNAME = "hidden";
const FILTER_BTN_COUNTER_ID = "filter-btn-counter";

export default class extends Controller {
  static targets = ["filterOption", "menu", "option"];

  initialize() {
    this.filterCounter = document.getElementById(FILTER_BTN_COUNTER_ID);
  }

  connect() {
    const checked = document.querySelectorAll("input[type=\"checkbox\"]:checked");
    Array.from(checked).map((c) => this.render(c));
  }

  toogleMenu() {
    this.menuTarget.classList.toggle(TOOGLE_CLASSNAME);
  }

  // hide menu when clicking ESC
  closeWithKeyboard(e) {
    if (e.code === "Escape") {
      this.menuTarget.classList.add(TOOGLE_CLASSNAME);
    }
  }

  // hide menu when clicking outside of menu
  closeBackground(e) {
    if (e && this.menuTarget.contains(e.target)) {
      return;
    }
    if (!TOOGLE_BTN_ELEMENTS_IDS.some((el) => el === e.target.id)) {
      this.menuTarget.classList.add(TOOGLE_CLASSNAME);
    }
  }

  countDisplayedLabels() {
    const labelList = document.getElementById("selected-filter-options");
    return labelList.querySelectorAll("label:not(.hidden)").length;
  }

  handleRender(event) {
    this.render(event.target);
  }

  render(target) {
    // Display/Hide labels
    target.labels.forEach((el) => {
      if (el.parentElement.id === "selected-filter-options") {
        el.classList.toggle("hidden");
      }
    });

    // Count items
    this.filterCounter.textContent = this.countDisplayedLabels();
  }
}
