import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['toggleBtn'];

  toggle(event) {
    const btnArr = event.target.parentNode.querySelectorAll('[data-toggle-password-btn]');
    btnArr.forEach(btn => btn.classList.toggle('is-hidden'));
    const password = event.target.closest('.fl-input__wrap').querySelector('input');
    const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
    password.setAttribute('type', type);
  }
}
