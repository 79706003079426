import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['secondsLeft', 'support', 'countdownWrap', 'title', 'infobox'];

  connect() {
    this.resendClicks = 0;
  }

  toggle(event) {
    const { resendLinkInfoboxCountdown, resendLinkInfoboxClicks } = event.target.dataset;
    const delay = parseInt(resendLinkInfoboxCountdown);
    const clicksMax = parseInt(resendLinkInfoboxClicks);
    const button = event.target;
    this.resendClicks += 1;

    this.updateUIOnButtonClick(this.resendClicks, clicksMax, button);
    this.startCountdown(delay);

    if (this.resendClicks < clicksMax) {
      setTimeout(() => {
        this.updateUIOnButtonClick(this.resendClicks, clicksMax, button);
      }, delay * 1000 + 1000)
    } else {
      this.supportTarget.classList.remove('is-hidden');
    }
  }

  updateUIOnButtonClick(resendClicks, clicksMax, button) {
    this.titleTarget.classList.toggle('is-hidden');
    this.infoboxTarget.classList.toggle('is-transparent');
    button.classList.toggle('is-hidden');
    (resendClicks < clicksMax) ? this.countdownWrapTarget.classList.toggle('is-hidden') : this.countdownWrapTarget.classList.add('is-hidden');
  }

  startCountdown(duration) {
    let secondsLeft = duration;
    const countdownInterval = setInterval(() => {
      if (secondsLeft <= 0) {
        clearInterval(countdownInterval);
      }
      this.secondsLeftTarget.textContent = secondsLeft;
      secondsLeft -= 1;
    }, 1000);
    this.secondsLeftTarget.textContent = duration;
  }
}
