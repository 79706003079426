import CoordinateObject from "./coordinate_object";

export default class PolygonObject {
  constructor(coordinates) {
    if (CoordinateObject.prototype.isPrototypeOf(coordinates[0])) {
      this.coordinates = coordinates;
    } else if (typeof coordinates[0] === "object") {
      this.coordinates = coordinates.map(([lng, lat]) => new CoordinateObject(lat, lng));
    } else if (coordinates.length === 0) {
      this.coordinates = [];
    } else {
      throw new Error("Unable to assign coordinates to polygon");
    }
  }

  polygonString() {
    let string = "POLYGON((";

    if (this.coordinates) {
      this.coordinates.forEach((coordinate) => (string += coordinate.coordinateString()));
      string += this.coordinates[0].coordinateString(false);
    }
    string += "))";
    return string;
  }
}
