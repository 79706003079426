import Flatpickr from 'stimulus-flatpickr'
import { english } from 'flatpickr/dist/l10n/default.js'
import { Norwegian } from 'flatpickr/dist/l10n/no.js'

export default class extends Flatpickr {
  locales = {
    nb: Norwegian,
    en: english
  }

  initialize() {
    this.config = {
      locale: this.locale,
    }

    super.connect();
  }

  get locale() {
    if (this.locales && this.data.has("locale")) {
      return this.locales[this.data.get("locale")];
    } else {
      return "";
    }
  }
}
