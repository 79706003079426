import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.originalDimensionsTop = document.getElementById(this.element.id).getBoundingClientRect().top;
    this.tableWrap = document.getElementById(this.element.id).closest("main");
    this.tableWrapOffsetTop = this.tableWrap.getBoundingClientRect().top;
    this.tableWrapPadding = this.originalDimensionsTop - this.tableWrapOffsetTop;
    this.tableHeight = window.innerHeight - this.tableWrapPadding;

    this.element.setAttribute("style", `max-height: ${this.tableHeight}px;`);
  }
}
