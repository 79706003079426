import {
  isBefore, isEqual, isAfter, parse, format,
} from "date-fns";
import { DATA_POINT_DATE_TIME_FORMAT } from "../chart/constants";
import { removeFixedTooltip } from "../chart";

class AccessibilityController {
  constructor({
    leftBtnTarget,
    rightBtnTarget,
    dataValue,
    chart,
    dataSetBounds,
  }) {
    this.leftBtnTarget = leftBtnTarget;
    this.rightBtnTarget = rightBtnTarget;
    this.dataValue = dataValue;
    this.chart = chart;
    this.dataSetBounds = dataSetBounds;
  }

  isValidDirection = (direction) => {
    const possibleDirections = ["right", "left"];
    return possibleDirections.includes(direction);
  };

  handleButtonVisibility = () => {
    const { scales } = this.chart.config.options;

    const isAtStart = this.isBelowOrEqual(
      format(new Date(scales.x.min), DATA_POINT_DATE_TIME_FORMAT),
      this.dataSetBounds.firstPoint,
    );

    const isAtEnd = this.isAboveOrEqual(
      format(new Date(scales.x.max), DATA_POINT_DATE_TIME_FORMAT),
      this.dataSetBounds.lastPoint,
    );

    ["opacity-20", "cursor-not-allowed"].forEach((el) => {
      this.leftBtnTarget.classList.toggle(el, isAtStart);
      this.rightBtnTarget.classList.toggle(el, isAtEnd);
    });
  };

  scroll({ direction }) {
    if (!this.isValidDirection(direction)) return;

    const goingLeft = direction === "left";

    removeFixedTooltip(this.chart);
    this.chart.pan({
      x: goingLeft ? 100 : -100,
      y: 0,
    }, null, 'easeInOutQuart');
    this.handleButtonVisibility();
  }

  isBelowOrEqual(dateString1, dateString2) {
    const date1 = parse(dateString1, DATA_POINT_DATE_TIME_FORMAT, new Date());
    const date2 = parse(dateString2, DATA_POINT_DATE_TIME_FORMAT, new Date());
    return isBefore(date1, date2) || isEqual(date1, date2);
  }

  isAboveOrEqual(dateString1, dateString2) {
    const date1 = parse(dateString1, DATA_POINT_DATE_TIME_FORMAT, new Date());
    const date2 = parse(dateString2, DATA_POINT_DATE_TIME_FORMAT, new Date());
    return isAfter(date1, date2) || isEqual(date1, date2);
  }
}

export default AccessibilityController;
