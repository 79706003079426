import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dropDown", "wrapper"];

  dropDownToggle() {
    this.dropDownTarget.classList.toggle("hidden");
  }

  dropDownCheck(e) {
    if (!this.wrapperTarget.contains(e.target) || e.target.matches('.dialog-backdrop')) {
      this.dropDownTarget.classList.add("hidden");
    }
  }
}
