import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["input", "counterDecrement"];

  initialize() {
    this.toggleDecrement();
  }

  increment() {
    this.inputTarget.stepUp();

    if (this.inputTarget.hasAttribute("submit_on_change")) {
      Rails.fire(this.inputTarget.form, "submit");
    }

    this.counterDecrementTarget.classList.remove("is-disabled")
  }

  decrement() {
    this.inputTarget.stepDown();

    if (this.inputTarget.hasAttribute("submit_on_change")) {
      Rails.fire(this.inputTarget.form, "submit");
    }

    this.toggleDecrement();
  }

  toggleDecrement() {
    this.inputMin = this.inputTarget.getAttribute("min");
    this.inputVal = this.inputTarget.value;
    this.counterDecrementTarget.classList.toggle("is-disabled", this.inputVal === this.inputMin);
  }
}
