import { Controller } from "@hotwired/stimulus";

function typecast(value) {
  try {
    return JSON.parse(value);
  } catch (oO) {
    return value;
  }
}

const getActionParams = (element) => {
  const params = {};
  const pattern = /^data-(.+)-param$/;
  Array.from(element.attributes).forEach((attr) => {
    const { name, value } = attr;
    const match = name.match(pattern);
    const key = match && match[1];
    if (key) {
      params[key] = typecast(value);
    }
  });

  return params;
};

const between = (x, min, max) => x >= min && x <= max;

/* eslint-disable prefer-promise-reject-errors */
const condition = (fnc) => new Promise((resolve, reject) => {
  if (fnc()) {
    resolve(true);
  } else {
    reject();
  }
});
/* eslint-enable prefer-promise-reject-errors */

export default class extends Controller {
  static values = {
    queryKey: String,
    query: String,
    matchKey: String,
    allowedValues: Array,
    timestampMin: Number,
    timestampMax: Number,
    hiddenCount: Number,
  };

  static targets = ["item"];

  initialize() {
    this.enabled = false;
    // Store all children as they are rendered on controller init
    this.seenChildren = Array.from(this.element.children).map(({ id }) => id);
    this.hiddenCountValue = 0;
  }

  connect() {
    this.enabled = true;
  }

  disconnect() {
    this.enabled = false;
  }

  hiddenCountValueChanged(value) {
    const spanElement = document.getElementById("filtered-records-count");
    if (spanElement) {
      if (value > 0) {
        spanElement.classList.remove("hidden");
      }
      const regex = /\(\d*\)/;
      const { innerText } = spanElement;
      spanElement.innerText = innerText.replace(regex, `(${this.hiddenCountValue})`);
    }
  }

  itemTargetConnected(element) {
    const { id } = element;

    if (!this.enabled || this.seenChildren.includes(id)) {
      // Not enabled or already seen, returning
      return;
    }

    // Get params connected to this element
    const params = getActionParams(element);

    const timeWithinRange = condition(() => {
      if (this.hasTimestampMaxValue && this.hasTimestampMinValue) {
        const createdAt = params["created-at"];
        const isBetween = between(createdAt, this.timestampMinValue, this.timestampMaxValue);
        return createdAt && isBetween;
      }
      return true;
    });

    const arrayMatch = condition(() => {
      if (this.hasAllowedValuesValue) {
        const hasAllowedValue = this.allowedValuesValue.includes(params[this.matchKeyValue]);
        return this.matchKeyValue in params && hasAllowedValue;
      }
      return true;
    });

    const stringSearch = condition(() => {
      if (this.hasQueryValue && this.hasQueryKeyValue) {
        const key = this.queryKeyValue;
        const str = params[key];
        const query = this.queryValue;
        return this.matchKeyValue in params && str.toLowerCase().indexOf(query.toLowerCase()) > -1;
      }
      return true;
    });

    Promise.all([timeWithinRange, arrayMatch, stringSearch])
      .then(() => {
        this.seenChildren.push(id);
      })
      .catch(() => {
        this.hiddenCountValue += 1;
        element.classList.add("hidden");
      });
  }
}
