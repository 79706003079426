import { Controller } from "@hotwired/stimulus";

let intervalIdentifiers = [];

export default class extends Controller {
  static targets = ["counter"];

  static values = { duration: Number };
  // duration in seconds

  start() {
    let countdown = Number(this.durationValue);

    this.counterTarget.textContent = countdown;

    const timerInterval = setInterval(() => {
      if (countdown === 0) {
        clearInterval(timerInterval);
        window.dispatchEvent(new CustomEvent("idle-timeout", {}));
      } else {
        countdown -= 1;
        this.counterTarget.textContent = countdown;
      }
    }, 1000);
    intervalIdentifiers.push(timerInterval);
  }

  stop() {
    intervalIdentifiers.forEach((el) => clearInterval(el));
    intervalIdentifiers = [];
  }
}
