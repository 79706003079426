import { Controller } from "@hotwired/stimulus";
/* global Turbo */

export default class extends Controller {
  initialize() {
    this.counter = 0;
    this.timer = null;
  }

  knock(event) {
    event.preventDefault();
    this.counter += 1;
    if (this.counter === 3) {
      Turbo.visit(event.target.href);
    } else {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);
        this.counter = 0;
      }, 3000);
    }
  }
}
