import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "buttonSubmit"];

  connect = () => {
    this.selectValue = this.selectTarget.value;
    this.submit = this.hasButtonSubmitTarget ? this.buttonSubmitTarget : null;
  };

  selectChange = () => {
    if (this.selectValue !== this.selectTarget.value) {
      this.submit?.classList.remove("fl-disabled");
    } else {
      this.submit?.classList.add("fl-disabled");
    }
  };
}
