import { Controller } from "@hotwired/stimulus";
import * as Sentry from "@sentry/browser";

export default class extends Controller {
  connect() {
    window.addEventListener("idle-timeout-restart", () => {
      this.hideKeyboard();
    })
  }

  disconnect() {
    window.removeEventListener("idle-timeout-restart", () => {
      this.hideKeyboard();
    })
  }

  showKeyboard() {
    if(typeof KioApp !== 'undefined') {
      if(typeof KioApp.StartKeyboard === 'function') {
        KioApp.StartKeyboard("", "", "text", false, "", "");
      } else if (typeof KioApp.startKeyboard === 'function') {
        KioApp.startKeyboard();
      } else {
        Sentry.captureMessage('No valid keyboard function found');
      }
    }
  }

  hideKeyboard() {
    if(typeof KioApp !== 'undefined') {
      if(typeof KioApp.CloseKeyboard === 'function') {
        KioApp.CloseKeyboard();
      } else if (typeof KioApp.closeKeyboard === 'function') {
        KioApp.closeKeyboard();
      } else {
        Sentry.captureMessage('No valid keyboard function found');
      }
    }
  }
}
