import { Controller } from "@hotwired/stimulus";

const inFuture = (ts) => (ts >= Date.now());

export default class extends Controller {
  static targets = ["item"];

  static values = {
    list: String,
    active: Array,
  };

  initialize() {
    this.localStorageKey = this.listName
    this.state = this.getStateFromLocalStorage();
    this.trimState();
    this.setDismissed();
    this.loaded = false;
  }

  connect() {
    this.displayItems();
    this.intervalTimer = setInterval(() => {
      this.displayItems();
    }, 60 * 1000);

    this.loaded = true;
  }

  disconnect() {
    clearInterval(this.intervalTimer);
    this.trimState();
  }

  setDismissed() {
    // Filter through the dismissed items.
    // if the dismissedUntil values is still in the future, we add the id to the dismissed array
    this.dismissed = this.state.filter((itm) => inFuture(itm.dismissedUntil)).map((itm) => itm.id);
  }

  displayItems() {
    this.setDismissed();

    // Hide all
    this.itemTargets.map((item) => { item.classList.add("!hidden"); return item; });

    // Loop through targets and mark first one as visible
    for (const item of this.itemTargets) {
      if (!this.dismissed.includes(item.id)) {
        // If the dismissed array does not hold this ID then we display it and break out of the loop
        item.classList.remove("!hidden", "hidden");
        return false;
      }
    }

    return true;
  }

  dismiss(event) {
    const { id, interval = 31556952000 } = event.params; // Default value of 1 year on interval
    const dismissedUntil = Date.now() + interval;
    const dismissedItem = { id, dismissedUntil };
    this.addOrReplaceInState(dismissedItem);

    // Find element and hide it immediately
    const item = document.getElementById(id);
    if (item) {
      item.classList.add("hidden");
    }

    // Get the next item to display
    this.displayItems();
  }

  itemTargetConnected(target) {
    // Ignore first time we see items (on page load)
    if (!this.loaded) {
      return;
    }

    // If item is marked as inactive we remove it from active array
    const { inactive } = target.dataset;
    if (inactive) {
      this.activeValue = this.activeValue.filter((id) => id !== target.id);
      this.trimState();
    }

    // Redraw the list of items
    this.displayItems();
  }

  addOrReplaceInState(dissmissedItem) {
    // Look for existing entry
    const foundIndex = this.state.findIndex((item) => item.id === dissmissedItem.id);

    if (foundIndex !== -1) {
      // Update if found
      this.state[foundIndex] = dissmissedItem;
    } else {
      // Or add new entry
      this.state.push(dissmissedItem);
    }

    // Save to localStorage.
    this.saveStateToLocalStorage(this.state);
  }

  getStateFromLocalStorage() {
    let existing = JSON.parse(localStorage.getItem(this.localStorageKey));
    existing = existing || [];
    return existing;
  }

  saveStateToLocalStorage() {
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.state));
  }

  trimState() {
    this.state = this.state.filter((item) => this.activeValue.includes(item.id));
    this.saveStateToLocalStorage();
  }

  get listName() {
    return this.listValue;
  }
}
