import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fullText", "truncatedText"];

  static values = { seeMore: String, seeLess: String };

  toggleText = (event) => {
    this.fullTextTarget.classList.toggle("is-hidden");

    if (this.hasTruncatedTextTarget) {
      this.truncatedTextTarget.classList.toggle("is-hidden");
    }

    if (this.fullTextTarget.classList.contains("is-hidden")) {
      event.srcElement.innerHTML = this.seeMoreValue;
    } else {
      event.srcElement.innerHTML = this.seeLessValue;
    }
  };
}
