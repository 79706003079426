import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["drawer"];
  static values = { open: Boolean }

  connect() {
    console.log("dew drawer connected. Trigger it by using CTRL + d")
  }

  toggle() {
    this.openValue = !this.openValue;
  }

  triggerAction(event) {
    console.log(event.target.value)
    const { action } = event.currentTarget.dataset;
    fetch(`/drivers/taxis/dev_actions`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ event: event.target.value })
    }).then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error('Error:', error));
  }
}
