import { Controller } from "@hotwired/stimulus";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";

gsap.registerPlugin(MotionPathPlugin);

export default class extends Controller {
  static targets = ["wrapper"];

  connect() {
    setInterval(this.toggleAnimation, 14000);
    this.moveCar();
  }

  toggleAnimation = () => {
    this.wrapperTarget.classList.remove("is-animated");

    setTimeout(() => {
      this.wrapperTarget.classList.add("is-animated");
      this.moveCar();
    }, 100)
  }

  moveCar() {
    let tl = gsap.timeline();
    tl.to("#Car", {
      motionPath: {
        path: "#Car-way path",
        align: "#Car-way path",
        alignOrigin: [0.5, 0.5],
      },
      transformOrigin: "50% 50%",
      duration: 4,
      delay: 4,
      ease: "linear"
      }
    )
    .addPause(5.5, function() {
      setTimeout(
        function() {
          tl.play();
        },
      4000);
    })
  }
}
