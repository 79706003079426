import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["state"]
  static values = {
    state: String,
    successfulState: String,
    errorState: String
  }

  connect() {}

  finish() {
    this.element.classList.remove("processor");
    this.element.classList.add("processing-complete");
  }

  stateTargetConnected(element) {
    const data = element.dataset

    if (data.state === this.successfulStateValue) {
      this.finish();
    }
  }

  handleAnimation(event) {
    if(event.animationName === "indicatorGrow") {
      this.element.classList.add("indicator-stop-rotation");
    }
  }
}
