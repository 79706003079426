/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

/**
 *
 * @param {Date} date1
 * @param {Date} date2
 * @returns {Boolean} value
 * @note This is an effort to omit momentjs/date-fns and in this scope(chartjs bundle) isSameDateTime function is undefined
 */
export const isSameDateTime = (date1, date2) => (
  date1.getFullYear() === date2.getFullYear()
    && date1.getMonth() === date2.getMonth()
    && date1.getDate() === date2.getDate()
    && date1.getHours() === date2.getHours()
);

export const currentLocale = () => {
  const metaTag = document.querySelector("meta[name=\"locale\"]");
  return metaTag?.content || null;
};

export const getHighestDataSetPoint = (dataPoints) => {
  const isMultipleDataSets = dataPoints.datasets.length > 1;

  let highestDataSetPoint = [];

  if (isMultipleDataSets) {
    highestDataSetPoint = dataPoints.datasets.reduce((acc, { data }) => {
      data.forEach((val, i) => {
        acc[i] = (acc[i] || 0) + val;
      });
      return acc;
    }, []);
  } else {
    highestDataSetPoint = dataPoints.datasets[0].data.map(({ y }) => y);
  }

  const maxPoint = Math.max(...highestDataSetPoint);
  return maxPoint;
};
