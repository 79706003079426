import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item"];

  static classes = ["highlighted", "regular"];

  search({ detail: { value } }) {
    this.itemTargets.forEach((item) => {
      if (item.dataset.label.toLowerCase().includes(value)) {
        item.classList.remove("hidden");
      } else {
        item.classList.add("hidden");
      }
    });
  }

  keyup(event) {
    event.preventDefault();
    event.stopPropagation();
    if (event instanceof KeyboardEvent) {
      if (event.keyCode === 13) {
        this.select(event)
      }
    }
  }

  select(event) {
    const { value, label } = event.currentTarget.dataset;
    const selectEvent = new CustomEvent("combo-item-selected", { detail: { value, label } });
    window.dispatchEvent(selectEvent);
    this.highlightSelected(value);
  }

  highlightSelected(value) {
    this.itemTargets.forEach((item) => {
      if (item.dataset.value === value) {
        item.classList.add(...this.highlightedClasses);
        item.classList.remove(...this.regularClasses);
      } else {
        item.classList.remove(...this.highlightedClasses);
        item.classList.add(...this.regularClasses);
      }
    });
  }

  clearHighlighted() {
    this.itemTargets.forEach((item) => {
      item.classList.remove(...this.highlightedClasses);
      item.classList.add(...this.regularClasses);
    });
  }
}
