import * as Sentry from "@sentry/browser";
import { Application } from "@hotwired/stimulus";
import Flatpickr from "stimulus-flatpickr";
import { getMetaValue } from "../helpers";

const application = Application.start();
application.warnings = true;
application.debug = false;
window.Stimulus = application;

application.handleError = (error, message, detail) => {
  Sentry.setUser({ id: getMetaValue("current-user-id"), taxiId: getMetaValue("current-taxi-id") });
  Sentry.setContext("StimulusError", {
    message,
    component: detail.identfier,
  });
  Sentry.captureException(error);
  console.error(error, message, detail);
};

// Controllers are fetched from app/javascript/controllers/**/*_controller.js
// and loaded automatically.
// Take great care when selecting a filename for a controller. The filename of the controller is used
// to identify the controller in the DOM. If you change the filename of a controller,
// you must also change the name of the controller in the DOM and its data-[identifier]-* attributes.
// For example, a file named confirm_action_controller.js will be identified as "confirm-action" in the DOM.
import controllers from "./**/*_controller.js"
controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default)
})
