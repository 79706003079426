import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["url", "timing"];

  connect() {
    const resetTiming = Number(this.timingTarget?.value);

    const idleDurationSecs = 60;
    const redirectUrl = this.urlTarget.dataset.url;
    let idleTimeout;

    const resetIdleTimeout = function () {
      if (idleTimeout) clearTimeout(idleTimeout);
      idleTimeout = setTimeout(() => location.href = redirectUrl, idleDurationSecs * resetTiming);
    };

    resetIdleTimeout();

    ["click", "touchstart", "mousemove"].forEach((evt) => document.addEventListener(evt, resetIdleTimeout, false));
  }
}
