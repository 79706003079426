import { Controller } from "@hotwired/stimulus";
import { decodePath } from "@googlemaps/google-maps-services-js/src/util";
/* global google */

export default class extends Controller {
  static targets = ["map"];

  static values = {
    directions: Object,
    hideControls: Boolean,
  };


  connect() {
    if (typeof (google) != "undefined"){
      this.initializeMap();
    }
  }

  initializeMap() {
    this.directions = this.directionsValue;
    const firstRoute = this.directions.routes[0];
    this.origin = firstRoute.legs[0].start_location;
    this.destination = firstRoute.legs[0].end_location;
    this.bounds = new google.maps.LatLngBounds(
      firstRoute.bounds.southwest,
      firstRoute.bounds.northeast,
    );

    const { request } = this.directions;
    request.origin = {
      location: new google.maps.LatLng(
        request.origin.lat,
        request.origin.lng,
      ),
    };

    request.destination = {
      location: new google.maps.LatLng(
        request.destination.lat,
        request.destination.lng,
      ),
    };

    this.directions.request = request;

    this.directions.routes = this.directions.routes.map((response) => {
      response.bounds = new google.maps.LatLngBounds(
        response.bounds.southwest,
        response.bounds.northeast,
      );

      response.overview_path = decodePath(response.overview_polyline.points);
      response.overview_path = response.overview_path.map((path) => new google.maps.LatLng(path.lat, path.lng));
      response.legs = response.legs.map((leg) => {
        leg.start_location = new google.maps.LatLng(leg.start_location.lat, leg.start_location.lng);
        leg.end_location = new google.maps.LatLng(leg.end_location.lat, leg.end_location.lng);
        leg.steps = leg.steps.map((step) => {
          step.path = decodePath(step.polyline.points);
          step.path = step.path.map((p) => new google.maps.LatLng(p.lat, p.lng));
          step.start_location = new google.maps.LatLng(
            step.start_location.lat,
            step.start_location.lng,
          );
          step.end_location = new google.maps.LatLng(
            step.end_location.lat,
            step.end_location.lng,
          );
          return step;
        });
        return leg;
      });

      return response;
    });

    this.map = new google.maps.Map(this.mapTarget, {
      zoom: 14,
      disableDefaultUI: true,
      clickableIcons: false,
    });

    const { map } = this;
    map.fitBounds(this.bounds);

    const rendererOptions = {
      map,
    };

    const directionDisplay = new google.maps.DirectionsRenderer(rendererOptions);
    this.directions.request.travelMode = "DRIVING";
    directionDisplay.setDirections(this.directions);
  }
}
