import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "submitButton" ];

  connect(){
    this.disableIfEmpty()
  }

  disableIfEmpty() {
    const value = this.inputTarget.value.trim();
    const isDisabled = value.length == 0;
    this.submitButtonTarget.classList.toggle("is-disabled", isDisabled);
  }
}
