/* eslint-disable import/prefer-default-export */
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";
import { format, parse } from "date-fns";
import nbLocale from "date-fns/locale/nb";
import enUSLocale from "date-fns/locale/en-US";
import { DATA_POINT_DATE_TIME_FORMAT } from "./constants";
import { currentLocale } from "./utils";

const availableLocales = {
  en: enUSLocale,
  nb: nbLocale,
  // add more locales here as needed
};

/**
 *
 * @param {Boolean} isMultipleDataSets
 * @param {Object} dataPoints { labels?: Array<String>, datasets: Array<Object<dataset>>}
 * @returns Annotations Object
 */
export const lineAnnotations = ({
  dataPoints,
  isMultipleDataSets,
}) => {
  const { datasets, labels = [] } = dataPoints;
  if (isEmpty(datasets)) return {};

  const mapped = (isMultipleDataSets ? labels : datasets[0].data).map((obj) => format(
    parse(
      isMultipleDataSets ? obj : obj.x,
      DATA_POINT_DATE_TIME_FORMAT,
      new Date(),
    ),
    "yyyy-MM-dd",
  ));

  const dateTimes = uniq(mapped);

  const locale = availableLocales[currentLocale()] || nbLocale;

  return {
    events: ["click"],
    annotations: dateTimes.map((date, idx) => ({
      id: `insights-annotation-${idx}`,
      type: "box",
      scaleID: "x",
      backgroundColor: "transparent",
      xMin: `${date} 00:00:00`,
      xMax: `${date} 23:59:59`,
      borderColor: "transparent",
      label: {
        position: "start",
        backgroundColor: "#ffffff",
        color: "#333333",
        font: {
          size: 18,
        },
        content: format(
          parse(`${date}`, "yyyy-MM-dd", new Date()),
          "EEEE MMM d, yyyy",
          {
            locale,
          },
        ),
        display: true,
      },
      click(context, event) {
        const {
          chart,
          element: { x: annotationStartingPoint },
        } = context;

        // ensuring this click is triggered from the annotation label placement area
        if (event.y < 50) {
          chart.pan(
            {
              x: -annotationStartingPoint,
              y: 0,
            },
            null,
            "easeInOutQuart",
          );
        }
      },
    })),
  };
};
