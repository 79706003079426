import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  toggle(event) {
    this.findTargets();

    event.target.classList.toggle('is-open');
    this.blockToToggle.classList.toggle('is-hidden')
  }

  toggleAttr(event) {
    this.findTargets();
    const inputs = this.blockToToggle.querySelectorAll(`${event.target.dataset.toggleBlockToggleAttrElem}`)

    inputs.forEach((el) => {
      el.toggleAttribute(`${event.target.dataset.toggleBlockToggleAttr}`)
    })
  }

  toggleRadio(event) {
    this.findTargets();

    this.blockToToggle.classList.toggle('is-hidden', event.target.value === "false" && event.target.checked);
    if (event.target.dataset.toggleBlockToggleAttr) {
      const inputs = this.blockToToggle.querySelectorAll(`${event.target.dataset.toggleBlockToggleAttrElem}`)

      inputs.forEach((el) => {
        el.toggleAttribute(`${event.target.dataset.toggleBlockToggleAttr}`, event.target.value === "true" && event.target.checked)
      })
    }
  }

  findTargets() {
    this.holder = event.target.closest('[data-toggle-block-target="holder"]');
    this.blockToToggle = this.holder.querySelector('[data-toggle-block-target="block"]');
  }
}
