import { Controller } from "@hotwired/stimulus";
import _ from "lodash";
import PolygonObject from "../lib/polygon_object";
/* global google */

export default class extends Controller {
  static values = {
    googleMapsApiKey: String,
    location: Object,
    rawPolygon: Array,
  };

  static targets = ["map", "hiddenInput"];

  initializeMap() {
    this.polygon = new PolygonObject(this.rawPolygonValue);
    const { lat, lng } = this.centerLatLng();

    this.map = new google.maps.Map(this.mapTarget, {
      center: { lat, lng },
      zoom: 13,
      streetViewControl: false,
    });

    this.marker = new google.maps.Marker({
      position: this.location,
      map: this.map,
    });

    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.HAND,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ["polygon"],
      },
      polygonOptions: {
        editable: true,
      },
    });

    this.drawingManager.setMap(this.map);

    this.listeners = [];
    this.shapes = [];

    // If controller is connected with given array of coordinates
    if (this.rawPolygonValue.length !== 0) {
      // Reset the shapes in case this a controller reconnect. I don't think we
      // render the shapes twice since it is probably pointing to the same object
      // in memory, but let's be safe.
      this.shapes = [];

      // Init a new PolygonObject
      this.polygon = new PolygonObject(this.rawPolygonValue);

      // Init a new Google Maps Polygon
      const poly = new google.maps.Polygon({
        paths: this.polygon.coordinates,
        editable: true,
        map: this.map,
      });

      // Push to shapes array for future reference
      this.shapes.push(poly);

      this.addPolygonToState(poly);
    }

    this.listeners.push(google.maps.event.addListener(
      this.drawingManager,
      "polygoncomplete",
      (polygon) => {
        this.shapes.forEach((shape) => shape.setMap(null));
        this.shapes.push(polygon);
        this.addPolygonToState(polygon);
      },
    ));
  }

  connect() {
    if (typeof (google) != "undefined"){
      this.initializeMap()
    }
  }

  disconnect() {
    this.listeners = this.listeners.filter((listener) => listener.remove());
  }

  centerLatLng() {
    if (this.polygon && (this.polygon.coordinates.length !== 0)) {
      return this.polygon.coordinates[0];
    }

    return this.locationValue;
  }

  addPolygonToState(poly) {
    const polyInstance = poly.getPath();

    const googlePolyArray = polyInstance.getArray();
    const coordArray = _.map(googlePolyArray, (latlng) => ([latlng.lng(), latlng.lat()]));
    // Send current coordinates to DOM
    // This allows user to return with history and not lose state
    this.rawPolygonValue = coordArray;
    // Output current polygon to hidden field
    this.polygon = new PolygonObject(coordArray);
    this.hiddenInputTarget.value = this.polygon.polygonString();

    // Add listeners for current polygon
    const callback = () => this.addPolygonToState(poly);
    this.addListener(polyInstance, "set_at", callback);
    this.addListener(polyInstance, "insert_at", callback);
  }

  addListener(instance, event, callback) {
    const addListenerFnc = google.maps.event.addListener;
    const newListener = addListenerFnc(
      instance,
      event,
      () => callback(),
    );
    this.listeners.push(newListener);
  }
}
