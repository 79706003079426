import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["radio", "click"];
  static values = { autoSubmit: { type: Boolean, default: false } };

  initialize = () => {
    if (this.autoSubmitValue) {
      this.autoSubmit = debounce(this.autoSubmit, 500).bind(this);
    }
  }

  disable() {
    this.element.querySelectorAll("[type=submit]").forEach((element) => {
      element.dataset["previousValue"] = element.value;
      element.value = element.dataset["disableWith"];
    });
  }

  reset() {
    this.element.querySelectorAll("[type=submit]").forEach((element) => {
      element.value = element.dataset["previousValue"];
      element.disabled = false;
    });
  }

  autoSubmit = (event) => {
    this.element.requestSubmit();
    this.toggleActive();
  }

  toggleActive = () => {
    this.radioTargets.map((el) => {
      el.checked ? el.classList.add("is-active") : el.classList.remove("is-active");
    });

  }
}
