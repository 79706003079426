import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { outputElementId: String };

  static targets = ["item"];

  itemTargetConnected() {
    this.updateCounter();
  }

  itemTargetDisconnected() {
    this.updateCounter();
  }

  updateCounter() {
    const itemsCount = this.itemTargets.length;
    const outputCounterElement = this.element.querySelector(`#${this.outputElementIdValue}`);
    if (outputCounterElement) outputCounterElement.textContent = itemsCount;
  }
}
