import { Controller } from "@hotwired/stimulus"
import { debounce } from "lodash"

export default class extends Controller {
  static targets = ["click", "trackedInput"]

  initialize() {
    this.click = debounce(this.click, 500)
    this.trackedInputs = {}
  }

  connect() {
    this.trackedInputTargets.forEach(target => {
      if (target.type === 'radio') {
        if (this.trackedInputs[target.name] === undefined) {
          this.trackedInputs[target.name] = null;
        }
      } else {
        this.trackedInputs[target.name] = target.value
      }
    })
  }

  fetchValues(event) {
    let hasChanged = false;

    this.trackedInputTargets.forEach(target => {
      if (target.type === 'radio') {
        if (target.checked && this.trackedInputs[target.name] !== target.value) {
          this.trackedInputs[target.name] = target.value;
          hasChanged = true;
        }
      } else {
        if (this.trackedInputs[target.name] !== target.value) {
          this.trackedInputs[target.name] = target.value
          hasChanged = true
        }
      }
    })

    if (hasChanged) {
      this.click()
    }
  }

  click() {
    this.clickTargets.forEach(target => target.click())
  }
}
