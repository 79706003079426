/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { format, parse } from "date-fns";
import { isSameDateTime } from "./utils";
import { getQueryParam } from "../helpers";
import {
  FILTER_QUERY_PARAM_NAME,
  DATA_POINT_DATE_TIME_FORMAT,
} from "./constants";

export const customToolTip = (context) => {
  const tooltipModel = context.tooltip;
  const dataPoint = get(tooltipModel, "dataPoints[0]", {});

  const {
    dataset: { toolTipNote, disableToolTip = false, singleSetToolTipNote },
    dataIndex,
    parsed: { x: parsedXAxisValue },
  } = dataPoint;

  const filterValue = getQueryParam({
    paramElement: FILTER_QUERY_PARAM_NAME,
  });
  const isFiltered = !isEmpty(filterValue) && !isEmpty(singleSetToolTipNote);
  const toUseToolTip = isFiltered ? singleSetToolTipNote : toolTipNote;

  const pointDateTime = new Date(parsedXAxisValue);
  const hourLabel = format(pointDateTime, "HH:mm");
  const pointNote = isEmpty(toUseToolTip) ? "" : toUseToolTip[dataIndex];
  const currentDate = new Date();

  const currentHour = isSameDateTime(pointDateTime, currentDate) && !isEmpty(toUseToolTip);

  // Tooltip Element
  const identifier = `chartjs-tooltip-${context.chart.id}`;
  const toolTipWrapperId = `chartjs-tooltip-wrapper-${context.chart.id}`;
  let tooltipEl = document.getElementById(identifier);

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = identifier;
    tooltipEl.innerHTML = `<div id=${toolTipWrapperId}></div>`;
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  const dottedLineColor = currentHour ? "#DC4F89" : "#9491A9";

  // Set Text
  const toolTipContainerStyle = "display:flex; flex-direction: column";
  const labelNoteStyle = "font-color: #2C2353; font-size: 14px; background-color: #fff; width: 100px; text-align: center; z-index: 2;  text-transform: capitalize;";
  const dottedLine = `content:''; position: absolute; z-index: -1; top: 0; bottom: 0; left: 50%; border-left: 2px dashed ${dottedLineColor}; height: ${tooltipModel.caretY - (window.innerWidth < 600 ? 30 : 30)}px;`;

  let innerHTML = `<div style="${toolTipContainerStyle}">`;
  let toolTipPlacementNo = 50;

  if (currentHour) {
    const currentHourNowContainer = "color: #fff; font-weight: bold; font-size:16px; background-color: #DC4F89; text-align: center; padding: 0px 5px; border-radius: 2px; z-index: 2;";
    const pointNoteStyle = "font-weight: bold; font-size:16px; color: #DC4F89; margin-left: 2px; text-transform: capitalize; width: 65px; background-color: #fff";

    innerHTML += `
        <div style="display:flex; flex-direction: row; padding-left: 60px;">
          <div style="${currentHourNowContainer}">Now</div>
          <div style="${pointNoteStyle}">${pointNote}</div>
        </div>
      `;
    toolTipPlacementNo = 85;
  } else {
    innerHTML += `<div style="${labelNoteStyle}"><span>${hourLabel} ${pointNote}</span></div>`;
  }
  innerHTML += `<div style="${dottedLine}"></div>`;
  innerHTML += "</div>";

  const toolTipWrapper = tooltipEl.querySelector(`#${toolTipWrapperId}`);
  toolTipWrapper.innerHTML = innerHTML;

  const position = context.chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  if (!disableToolTip) {
    tooltipEl.style.opacity = 1;
  }
  tooltipEl.style.position = "absolute";
  tooltipEl.style.left = `${
    position.left
    + window.pageXOffset
    + (tooltipModel.caretX - toolTipPlacementNo)
  }px`;
  tooltipEl.style.top = `${position.top + window.pageYOffset + (window.innerWidth < 600 ? 30 : 30)}px`;
  tooltipEl.style.padding = `${tooltipModel.padding}px ${tooltipModel.padding}px`;
  tooltipEl.style.pointerEvents = "none";
};

const isChartAvailable = (chart) => {
  const { controller } = chart.getDatasetMeta(0);
  // this ensures there's at least a dataset in the chart before trying to trigger the tooltip
  return !isEmpty(controller);
};

/**
 *
 * @param {unknown} chart
 * @param {Number} toolTipIndex
 */

const triggerTooltip = (chart, toolTipIndex) => {
  if (!isChartAvailable(chart)) return;

  const { tooltip } = chart;
  if (!tooltip) return;

  if (tooltip.getActiveElements().length > 0) {
    tooltip.setActiveElements([], { x: 0, y: 0 });
  } else {
    const { chartArea } = chart;
    if (chartArea) {
      tooltip.setActiveElements(
        [
          {
            datasetIndex: 0,
            index: toolTipIndex,
          },
        ],
        {
          x: (chartArea.left + chartArea.right) / 2,
          y: (chartArea.top + chartArea.bottom) / 2,
        },
      );
    }
  }
};

export const removeFixedTooltip = (chart) => {
  if (!isChartAvailable(chart)) return;

  const { tooltip } = chart;
  tooltip.setActiveElements([], { x: 0, y: 0 });
};

/**
 *
 * @param {Object} {toolTipIndex : Array<String>, chart: unknown, parentNode: HTMLElement, scrollToActiveBar: Boolean}
 */
export const handleTriggerToolTip = ({
  xAxisPoints,
  chart,
}) => {
  const currentDate = new Date();
  const toolTipIndex = xAxisPoints.findIndex((val) => isSameDateTime(
    parse(val, DATA_POINT_DATE_TIME_FORMAT, new Date()),
    currentDate,
  ));
  if (toolTipIndex !== -1) {
    setTimeout(() => {
      triggerTooltip(chart, toolTipIndex);
    }, 1000);
  }
};
