import { Controller } from "@hotwired/stimulus"
import * as Sentry from "@sentry/browser";

export default class extends Controller {
  connect () {
    document.addEventListener("turbo:frame-missing", this.handleMissingFrame)
  }

  handleMissingFrame (event) {
    Sentry.withScope(function (scope) {
      scope.setTag("url", window.location.href);
      scope.setTag("event", "turbo:frame-missing");
      scope.setTag("frame", event.target.id);
      Sentry.captureMessage("turbo_frame_missing");
    });

    // Visit the response
    event.preventDefault()
    event.detail.visit(event.detail.response)
  }
}
