import { Controller } from "@hotwired/stimulus";
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";

export default class extends Controller {
  static values = {
    config: Object
  }

  initialize() {
    if (!this.firebase) {
      this.firebase = firebase.initializeApp(this.configValue)
    }
  }

  connect() {
    this.ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(this.firebase.auth());

    this.ui.start('#firebaseui-auth-container', {
      signInSuccessUrl: '/',
      signInOptions: [
        {
          provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
          recaptchaParameters: {
            type: 'image',
            size: 'invisible',
            badge: 'bottomright'
          },
          defaultCountry: 'NO',
          whitelistedCountries: ['NO'],
          language: this.localeValue,
        }
      ],
      callbacks: {
        signInSuccessWithAuthResult: function(_authResult, _redirectUrl) {
          // User successfully signed in.
          const tokenPromise = firebase.auth().currentUser.getIdToken()
          tokenPromise.then((token) => {
            const input = document.getElementById("drivers_otp_token")
            input.value = token
          }).then(() => {
            const form = document.getElementById("new_drivers_otp")
            form.submit()
          })

          return false;
        },
      },
    });
  }

  disconnect() {
    if (this.ui) {
      this.ui.delete().then(() => {
        this.ui = null;
      });
    }
  }
}
