import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["triggerer", "input"];

  connect() {
    this.inputTarget.value = "";
  }

  toggleDisable = () => {
    if (this.triggererTarget.checked) {
      this.inputTarget.disabled = false;
    } else {
      this.inputTarget.disabled = true;
      this.inputTarget.value = "";
    }
  };
}
