import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "anchor" ]

  encodeForm() {
    const form = this.element
    const formData = new FormData(form);
    const queryString = new URLSearchParams(formData).toString();

    for (const anchor of this.anchorTargets) {
      anchor.search = queryString
    }

    form.dispatchEvent(new CustomEvent("formEncoded", {}));
  }
}
