import { BridgeComponent } from "@hotwired/strada"

export default class extends BridgeComponent {
  static component = "sign-out"
  static targets = []

  signOut(event) {
    event.preventDefault()
    event.stopImmediatePropagation()
    this.send("signOut", { }, () => {})
  }
}
