import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["frame", "template"];

  show() {
    this.element.classList.remove("hidden");
  }

  hide() {
    this.frameTarget.innerHTML = "<div></div>";
    this.frameTarget.appendChild(this.templateTarget.content.cloneNode(true));
    this.element.classList.add("hidden");
  }
}
