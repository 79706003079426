export const alterElement = ({ elementId, classNames, isAddClassNames }) => {
  const element = document.getElementById(elementId);
  if (isAddClassNames) {
    element?.classList.add(...classNames);
  } else {
    element?.classList.remove(...classNames);
  }
};

export const appendQueryParam = ({ paramElement, paramValue, isArrayOfParams = false }) => {
  const urlSearchParams = window.location.search;
  const urlParam = new URLSearchParams(urlSearchParams);
  const oldParamValue = urlParam.get(paramElement);
  const isCurrentParam = oldParamValue === paramValue;

  // Removes the param if it is already in the url and leaves it if we need an array of params
  if (!isArrayOfParams) urlParam.delete(paramElement);

  if (!isCurrentParam) {
    const newUrl = `${window.location.protocol
    }//${
      window.location.host
    }${window.location.pathname
    }${urlSearchParams ? `?${urlParam}&` : "?"}${paramElement}=${paramValue}`;

    window.history.pushState({ path: newUrl }, "", newUrl);
  }
};

export const getQueryParam = ({ paramElement, isArrayOfParams = false }) => {
  const urlSearchParams = window.location.search;
  const urlParam = new URLSearchParams(urlSearchParams);
  const urlParamElementValue = isArrayOfParams ? urlParam.getAll(paramElement) : urlParam.get(paramElement);

  return urlParamElementValue;
};

export const scrollToThisSection = ({ sectionId }, omitDistance = 0) => {
  const yOffset = -10;
  const destination = document.querySelector(sectionId)?.getBoundingClientRect()?.top
    + window.pageYOffset
    + yOffset;
  window.scrollTo({ top: destination - omitDistance, behavior: "smooth" });
};

export const removeQueryParam = ({ paramElement, paramValue = null, isArrayOfParams = false }) => {
  const urlSearchParams = window.location.search;
  let urlParam = new URLSearchParams(urlSearchParams);

  if (!isArrayOfParams) {
    urlParam.delete(paramElement);
  } else {
    const toKeepParams = [];

    urlParam.forEach((value, key) => {
      if (key !== paramElement || value !== String(paramValue)) {
        toKeepParams.push([key, value]);
      }
    });
    urlParam = new URLSearchParams(toKeepParams);
  }

  const newUrl = `${window.location.protocol
  }//${
    window.location.host
  }${window.location.pathname
  }?${urlParam}`;

  window.history.pushState({ path: newUrl }, "", newUrl);
};
