import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  toggleRequiredCheckboxes(event) {
    this.findTargets();
    this.checkboxes.forEach(function (node) {
      node.required = event.srcElement.value === 'true';
    });
  }

  findTargets() {
    this.holder = event.target.closest('[data-toggle-required-checkboxes-target="holder"]');
    this.blockToToggle = this.holder.querySelector('[data-toggle-required-checkboxes-target="block"]');
    this.checkboxes = this.blockToToggle.querySelectorAll('input[type="checkbox"]');
  }
}
