import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.map = document.getElementById("map")
    this.mapController = document.getElementById("map").mapController
  }

  changePrice(){
    let newPrice = event.target.value
    let updatePricetag = event.target.classList.contains("price-to-show")

    if(this.isValidPrice(newPrice)){
      this.updateAnimation()
      this.getForm().submit()
      if(updatePricetag){
        this.mapController.updatePrice(event.target.dataset.zone, newPrice)
      }
    }else {
      event.target.value = this.currentPrice
      this.updateAnimation(false)
    }

  }

  isValidPrice(price){
    return !isNaN(+price) && price.replaceAll(' ', '') !== ''
  }

  setCurrentPrice(){
    this.currentPrice = event.target.value
  }

  updateAnimation(success = true){
    let formElement = this.getForm()
    let colorDeg = success ? 120 : 0
    let alpha = 1.0

    formElement.style.backgroundColor = `hsla(${colorDeg}, 100%, 80%, ${alpha})`

    let fader = setInterval(function(){
      alpha -= 0.1
      formElement.style.backgroundColor = `hsla(${colorDeg}, 100%, 80%, ${alpha})`
      if(alpha < 0.1){clearInterval(fader)}
    }, 100)
  }

  getForm(){
    let element = event.target
    while(element.localName != "form"){
      element = element.parentNode
    }
    return element
  }
}
