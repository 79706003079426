import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

export default class extends Controller {
  static targets = ["searchField", "valueField"];

  connect() {
    if (!this.valueFieldTarget.value) {
      this.valueFieldTarget.value = this.searchFieldTarget.value;
    }

    new SlimSelect({
      select: this.searchFieldTarget,
      events: {
        afterChange: (newVal) => {
          this.valueChanged(newVal[0].value);
        },
      },
    });
  }

  valueChanged(value) {
    this.valueFieldTarget.value = value;
  }
}
