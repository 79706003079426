import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["numberInput"];

  increment() {
    this.numberInputTarget.stepUp();
  }

  decrement() {
    this.numberInputTarget.stepDown();
  }
}
