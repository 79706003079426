import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["lat", "lng", "click", "address"];
  static values = {autoSubmit: {
      type: Boolean,
      default: true
    }}

  handleNewDestination(event) {
    const { detail } = event;
    const { destination, address } = detail;
    this.latTarget.value = destination.lat;
    this.lngTarget.value = destination.lng;
    if(address) {
      this.addressTarget.value = address;
    }

    this.autoSubmit()
  }

  invalid(event) {
    this.latTarget.value = "";
    this.lngTarget.value = "";
    this.addressTarget.value = "";

    this.autoSubmit()
  }

  autoSubmit(_) {
    if(this.autoSubmitValue) {
      this.element.requestSubmit(this.clickTarget);
    }
  }
}
