import { Controller } from "@hotwired/stimulus";
import { alterElement } from "./helpers";

export default class extends Controller {
  enableDisableElement({ params: { element } }) {
    const checkBoxValue = document.getElementById(this.element.id).checked;
    const targetElement = document.getElementById(element);

    alterElement({
      elementId: element,
      classNames: ["bg-gray-300"],
      isAddClassNames: !checkBoxValue,
    });
    alterElement({
      elementId: element,
      classNames: ["bg-indigo-600", "hover:opacity-80"],
      isAddClassNames: checkBoxValue,
    });
    targetElement.disabled = !checkBoxValue;
  }

  submitForm(ev) {
    ev.target.form.requestSubmit();
  }

  showHideElement({ params: { elementId, indicatorId } }) {
    const targetElement = document.getElementById(elementId);
    const indicatorElement = document.getElementById(indicatorId);

    targetElement?.classList.toggle("hidden");
    indicatorElement?.classList.toggle("rotate-180");
  }
}
