import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    src: String,
    interval: {
      type: Number,
      default: 60000
    },
  };

  connect() {
    this.timeout = setInterval(() => {
      let element = this.element;

      // Go up through parent elements until reaching a "turbo-frame" or the top of the DOM
      while (element && element.tagName.toLowerCase() !== "turbo-frame") {
        element = element.parentElement;
      }

      if (element && element.tagName.toLowerCase() === 'turbo-frame') {
        // Check for src attribute
        if(!element.hasAttribute('src')) {
          element.setAttribute('src', this.srcValue);
        }

        element.reload();
      }

    }, this.intervalValue);
  }

  disconnect() {
    clearInterval(this.timeout);
  }
}
