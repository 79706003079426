import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["counter"];

  static values = { duration: Number, unit: {type: String, default: ""}};

  connect() {
    this.start();
  }

  start() {
    let countdown = Number(this.durationValue);

    this.counterTarget.textContent = this.formattedTime(countdown);

    const timerInterval = setInterval(() => {
      if (countdown === 0) {
        clearInterval(timerInterval);
        this.finish();
      } else {
        countdown -= 1;
        this.counterTarget.textContent = this.formattedTime(countdown);
      }
    }, 1000);
  }

  finish() {
    this.hideCountdown();
  }

  hideCountdown() {
    this.element.style = "display: none";
  }

  formattedTime(timeInSeconds) {
    let minutes = Math.floor(timeInSeconds / 60);
    let seconds = timeInSeconds % 60;
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return (`${minutes}:${seconds}${this.unitValue && " "}${this.unitValue}`);
  }
}
