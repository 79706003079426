/* eslint-disable max-len */

export default ({
  dataValue
}) => ({
  indexAxis: 'y',
  scales: {
    y: {
      display: true,
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
      ticks: {
        color: "#272727",
        font: {
          weight: dataValue.datasets[0].labelFontWeight,
        }
      }
    },
    x: {
      type: "linear",
      min: 0,
      display: false,
      ticks: {
        display: false,
      },
    },
  },
  
  plugins: {
    legend: false,
    title: false,
    tooltip: {
      enabled: false,
    },

    decimation: {
      enabled: true,
    },
    zoom: {
      enabled: false
    },
    datalabels: {
      color:  dataValue.datasets[0].datalabelColor,
      font: {
        size: 16,
        weight: "bold"
      },
      anchor: 'start',
      align: 'end',
      formatter: function(value, context) {
        return value.x;
      }
    }
  },

  events: ['click'], // this prevents showing tooltip on mouse hover
});
