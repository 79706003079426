/* eslint-disable array-callback-return */
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["searchText", "select", "list", "datalist", "toggleBtn", "clearBtn", "item"];

  static classes = ["highlighted", "regular"];

  connect() {
    let value;
    if (this.selectTarget.selectedIndex) {
      const index = this.selectTarget.selectedIndex;
      const label = this.selectTarget.options[index]?.label;
      value = this.selectTarget.options[index]?.value;
      this.select({ detail: { label, value } });
    }

    this.searchTextTarget.tabIndex = 1;
    this.selectTarget.tabIndex = -1;

    const datalist = this.datalistTarget;
    const { options } = this.selectTarget;

    Array.from(options).forEach((originalOption, index) => {
      const option = document.createElement("div");
      const selectedClassName = value === originalOption.value ? this.highlightedClasses.join(" ") : this.regularClasses.join(" ");

      option.className = `w-full flex relative cursor-default select-none py-2 pl-3 pr-9 truncate ${selectedClassName}`;
      option.classList.add("hidden");
      option.innerText = originalOption.label;
      option.dataset.comboItemTarget = "item";
      option.dataset.action = "click->combo-item#select keyup->combo-item#keyup";
      option.dataset.value = originalOption.value;
      option.dataset.label = originalOption.label;
      option.tabIndex = index + 1;

      datalist.appendChild(option);
    });

    this.listTarget.appendChild(datalist);
    this.searchTextTarget.addEventListener("keydown", this.handleKeyDown);
  }

  disconnect(){
    this.searchTextTarget.removeEventListener("keydown", this.handleKeyDown);
  }

  toogleList() {
    this.listTarget.classList.toggle("hidden");
    this.dispatchSearchEvent("");
    this.scrollToSelectedOption();
  }

  closeWithKeyboard(e) {
    if (e.code === "Escape") {
      this.listTarget.classList.add("hidden");
      this.handleActionButtons({ text: "" });
    }
  }

  closeBackground(e) {
    if (!this.element.contains(e.target)) {
      this.listTarget.classList.add("hidden");
      this.handleActionButtons({ text: "" });
    }
  }

  inputFocus() {
    this.listTarget.classList.remove("hidden");
    const value = this.searchTextTarget.value.toLowerCase();
    this.dispatchSearchEvent(value);
    this.handleActionButtons({ text: value });
    this.dispatchSearchEvent("");
  }

  inputText(event) {
    const value = event.currentTarget.value.toLowerCase();
    this.dispatchSearchEvent(value);
    this.handleActionButtons({ text: value });
    if (value.trim() == "") {
       this.selectTarget.value = "";
     }
  }

  dispatchSearchEvent(value) {
    const searchEvent = new CustomEvent("combo-list-search", { detail: { value } });
    window.dispatchEvent(searchEvent);
  }

  dispatchClearHighlightedEvent() {
    const clearHighlightedEvent = new CustomEvent("combo-list-clear");
    window.dispatchEvent(clearHighlightedEvent);
  }

  select({ detail: { label, value } }) {
    this.selectTarget.value = value;
    this.searchTextTarget.value = label;
    this.toogleList();
  }

  clearText() {
    this.selectTarget.value = "";
    this.searchTextTarget.value = "";
    this.listTarget.classList.remove("hidden");
    this.dispatchSearchEvent("");
    this.searchTextTarget.focus();
    this.handleActionButtons({ text: "" });
    this.dispatchClearHighlightedEvent();
  }

  handleActionButtons({ text }) {
    if (text.length) {
      this.clearBtnTarget.classList.remove("hidden");
      this.toggleBtnTarget.classList.add("hidden");
    } else {
      this.clearBtnTarget.classList.add("hidden");
      this.toggleBtnTarget.classList.remove("hidden");
    }
  }

  scrollToSelectedOption() {
    if (this.selectTarget.selectedIndex) {
      const index = this.selectTarget.selectedIndex;
      const value = this.selectTarget.options[index]?.value;
      const selectedOption = document.querySelector(`[data-value="${value}"]`);
      selectedOption?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  
}
