import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "wrapper"];

  static values = { seconds: Number, onTimeout: String, silent: Boolean };

  initialize() {
    this.timer = null;
  }

  connect() {
    this.idleTimer();
  }

  idleTimer() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (this.silentValue) {
        window.dispatchEvent(new CustomEvent("idle-timeout", {}));
      } else {
        this.contentTarget.classList.remove("hidden");
        window.dispatchEvent(new CustomEvent("idle-timeout-start", {}));
      }
    }, this.secondsValue * 1000);
  }

  disconnect() {
    clearTimeout(this.timer);
  }

  resetIdleTimeout() {
    window.dispatchEvent(new CustomEvent("idle-timeout-stop", {}));
  }

  close() {
    this.contentTarget.classList.add("hidden");
    this.resetIdleTimeout();
  }

  closeBackground(ev) {
    if (ev && !this.wrapperTarget.contains(ev.target)) {
      this.close();
    }
  }

  handleIdleTimeout() {
    window.dispatchEvent(new CustomEvent("idle-timeout-restart", {}));
    window.location.href = this.onTimeoutValue;
  }
}
