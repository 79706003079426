import { Controller } from "@hotwired/stimulus";

const TOGGLE_CLASSNAME = "hidden";

export default class GroupedButtonController extends Controller {
  static targets = ["menu"];

  toogleMenu() {
    this.menuTarget.classList.toggle(TOGGLE_CLASSNAME);
  }

  // hide menu when clicking ESC
  closeWithKeyboard(e) {
    if (e.code === "Escape") {
      this.menuTarget.classList.add(TOGGLE_CLASSNAME);
    }
  }

  // hide menu when clicking outside of menu
  closeBackground(evt) {
    if (
      !this.menuTarget.classList.contains(TOGGLE_CLASSNAME)
      && !this.element.contains(evt?.target)
    ) {
      this.menuTarget.classList.add(TOGGLE_CLASSNAME);
    }
  }
}
