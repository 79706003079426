/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';
import datalabelsPlugin from 'chartjs-plugin-datalabels';
import AvailableTaxisChartOptions from './insights/available_taxis_chart_options';

export default class extends Controller {
  static targets = ['canvas'];

  static values = {
    data: Object,
  };

  connect() {
    const element = this.canvasTarget;
    
    this.chartData = this.dataValue;
   
    this.chart = new Chart(element.getContext('2d'), {
      plugins: [datalabelsPlugin],
      type: "bar",
      parsing: false,
      normalized: true,
      data: this.chartData,
      options: AvailableTaxisChartOptions({
        dataValue: this.dataValue,
      }),
    });

    this.chart.canvas.style.touchAction = "pan-y";
  }

  disconnect() {
    // handle webkit memory leak
    this.chart.canvas.width = 0;
    this.chart.canvas.height = 0;

    const ctx = this.chart.canvas.getContext('2d');
    if (ctx) ctx.clearRect(0, 0, 1, 1);

    this.chart.destroy();
    this.chart = undefined;
  }
}
