export default class CoordinateObject {
  constructor(lat, lng) {
    this.lat = lat;
    this.lng = lng;
  }

  coordinateString(trailingComma = true) {
    return `${this.lng} ${this.lat}${trailingComma ? "," : ""}`;
  }
}
