import { Controller } from "@hotwired/stimulus";
/* global google */

export default class extends Controller {
  static values = {
    googleMapsApiKey: String,
    location: Object,
    zoneId: String,
  };

  static targets = ["map"];

  initializeMap() {
    this.location = this.locationValue;

    const { lat, lng } = this.location;
    this.map = new google.maps.Map(this.mapTarget, {
      center: { lat, lng },
      zoom: 10,
      streetViewControl: false,
    });
    this.map.data.loadGeoJson(`/backoffice/zones/geojson?id=${this.zoneIdValue}`);
  }

  connect() {
    if (typeof (google) != "undefined"){
      this.initializeMap()
    }
  }
}
