import { Controller } from "@hotwired/stimulus";
import dialogPolyfill from 'dialog-polyfill'
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    title: String, description: String, confirmText: String, cancelText: String,
  };

  show(event) {
    event.preventDefault();

    const turboConfirmContent = document.getElementById("turbo-confirm-template");
    const fragment = document.createRange().createContextualFragment(turboConfirmContent.innerHTML);
    document.body.appendChild(fragment);

    this.dialog = document.getElementById("turbo-confirm");
    dialogPolyfill.registerDialog(this.dialog)

    if (this.hasTitleValue) {
      this.dialog.querySelector("h2").textContent = this.titleValue;
    }
    if (this.hasDescriptionValue) {
      this.dialog.querySelector("p").textContent = this.descriptionValue;
    }

    if (this.hasConfirmTextValue) {
      this.dialog.querySelector("[data-behavior='commit']").textContent = this.confirmTextValue;
    }

    if (this.hasCancelTextValue) {
      this.dialog.querySelector("[data-behavior='cancel']").textContent = this.cancelTextValue;
    }

    this.dialog.querySelector("[data-behavior='cancel']").addEventListener("click", () => {
      this.dialog.remove();
    }, { once: true });

    this.dialog.querySelector("[data-behavior='commit']").addEventListener("click", (_event) => {
      this.dialog.remove();
      this.submitForm(_event);
    }, { once: true });

    this.dialog.showModal();
  }

  submitForm = () => {
    Rails.fire(this.element, "submit");
  };
}
