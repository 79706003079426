import { Controller } from "@hotwired/stimulus";
import {
  alterElement,
  appendQueryParam,
  getQueryParam,
  removeQueryParam,
} from "./helpers";

const rangeValues = Object.freeze({
  START_DATE: "start_date",
  END_DATE: "end_date",
});

const DATE_PICKER_COMPONENT_ID = "#date-picker-range";
const DATE_PICKER_CALENDAR_ICON_ID = "date-picker-calendar-icon";
const DATE_PICKER_CLOSE_ICON_ID = "date-picker-close-icon";

export default class extends Controller {
  static targets = ["startDate", "endDate"];

  static values = {
    maxDate: String,
    minDate: String,
    autoSubmit: String,
    addOptionToParam: String,
  };

  connect() {
    this.isSubmitFormOnSelect = this.autoSubmitValue === "true";
    this.isAddValueToParam = this.addOptionToParamValue === "true";
    this.handleInitialValue();
  }

  handleInitialValue() {
    const startDateValue = getQueryParam({
      paramElement: rangeValues.START_DATE,
    });
    const endDateValue = getQueryParam({
      paramElement: rangeValues.END_DATE,
    });

    const isRangeAvailable = !!startDateValue && !!endDateValue;
    const datePicker = document.querySelector(DATE_PICKER_COMPONENT_ID);
    this.showHideIcons({ isHideCalendarIcon: isRangeAvailable });
    setTimeout(() => {
      if (isRangeAvailable) {
        datePicker._flatpickr.setDate([
          this.formatDate(startDateValue, true),
          this.formatDate(endDateValue, true),
        ]);
        this.startDateTarget.value = startDateValue;
        this.endDateTarget.value = endDateValue;
      }
      // handle setting max and min date
      if (this.maxDateValue) {
        datePicker._flatpickr.set("maxDate", new Date(this.maxDateValue));
      }
      if (this.minDateValue) {
        datePicker._flatpickr.set("minDate", new Date(this.minDateValue));
      }
    }, 1500);
  }

  setDate(ev) {
    const [startDateValue, endDateValue] = ev.target._flatpickr.selectedDates;

    if (startDateValue && endDateValue) {
      if (this.isAddValueToParam) {
        appendQueryParam({
          paramElement: rangeValues.START_DATE,
          paramValue: this.formatDate(startDateValue),
        });
        appendQueryParam({
          paramElement: rangeValues.END_DATE,
          paramValue: this.formatDate(endDateValue),
        });
      }
      this.startDateTarget.value = this.formatDate(startDateValue);
      this.endDateTarget.value = this.formatDate(endDateValue);
      if (this.isSubmitFormOnSelect) window.location.reload();
    }
    this.showHideIcons({ isHideCalendarIcon: true });
  }

  clearDate() {
    const datePicker = document.querySelector(DATE_PICKER_COMPONENT_ID);
    const [startDateValue, endDateValue] = datePicker._flatpickr.selectedDates;

    if (!!startDateValue && !!endDateValue) {
      datePicker._flatpickr.clear();
      this.showHideIcons({ isHideCalendarIcon: false });

      removeQueryParam({ paramElement: rangeValues.START_DATE });
      removeQueryParam({ paramElement: rangeValues.END_DATE });
      this.startDateTarget.value = "";
      this.endDateTarget.value = "";
      if (this.isSubmitFormOnSelect) window.location.reload();
    } else datePicker._flatpickr.open();
  }

  showHideIcons({ isHideCalendarIcon = true }) {
    alterElement({
      elementId: DATE_PICKER_CALENDAR_ICON_ID,
      classNames: ["hidden"],
      isAddClassNames: isHideCalendarIcon,
    });
    alterElement({
      elementId: DATE_PICKER_CLOSE_ICON_ID,
      classNames: ["hidden"],
      isAddClassNames: !isHideCalendarIcon,
    });
  }

  formatDate(givenDate, isDatePicker = false) {
    if (isDatePicker) {
      const [day, month, year] = givenDate.split("-");
      return new Date(year, month - 1, day);
    }

    const formattedDate = new Date(givenDate);
    const yyyy = formattedDate.getFullYear();
    let mm = formattedDate.getMonth() + 1;
    let dd = formattedDate.getDate();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    return `${dd}-${mm}-${yyyy}`;
  }
}
