import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    removal: {
      type: Boolean,
      default: false
    },
  }

  select(event) {
    if (this.removalValue === false) {
      event.preventDefault()

      // Ensure that the input has multiple = false before submitting
      // and remove [] from the end of the name
      event.target.name = event.target.name.replace("[]", '')
      event.target.form.requestSubmit()
    }
  }

  displayRemoval() {
    this.removalValue = true
  }

  hideRemoval() {
    this.removalValue = false
  }
}
