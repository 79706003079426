import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { tooltip: String };

  copyToClipboard(event) {
    const { content } = event.params;

    const { target } = event;
    navigator.clipboard.writeText(content).then(() => this.displayTooltip(content, target));
  }

  displayTooltip(content, target) {
    const tooltip = document.createElement("div");
    const tooltipContent = document.createTextNode(this.tooltipValue);
    tooltip.appendChild(tooltipContent);
    tooltip.classList.add("fl-copy__tooltip", "fl-is-visible");
    target.appendChild(tooltip);

    setTimeout(() => {
      tooltip.remove();
    }, 1000);
  }
}
