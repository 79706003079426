import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  toggleGroup = (event) => {
    const subgroups = event.target.closest(".fl-accordion__group").querySelectorAll(":scope > .fl-accordion__group");
    event.target.closest(".fl-accordion__group").classList.toggle("is-open");
    subgroups.forEach((subgroup) => {
      subgroup.classList.toggle("is-hidden");
    });
  };
}
