import { Controller } from '@hotwired/stimulus';

const directions = ['rotate-0', 'rotate-90', 'rotate-180', 'rotate-270'];

export default class extends Controller {
  static targets = ['objective'];

  static values = {
    current: { type: String, default: '' },
  };

  connect() {
    this.objectiveTarget.classList.add(directions[0]);
    const [initialValue] = directions;
    this.currentValue = initialValue;
  }

  left() {
    this.rotate('left');
  }

  right() {
    this.rotate('right');
  }

  rotate(direction) {
    let currentPosition = directions.indexOf(this.currentValue);
    this.objectiveTarget.classList.remove(directions[currentPosition]);

    if (direction === 'right') {
      currentPosition = (currentPosition + 1) % 4;
    } else {
      currentPosition = (currentPosition - 1 + 4) % 4;
    }
    this.objectiveTarget.classList.add(directions[currentPosition]);
    this.currentValue = directions[currentPosition];
  }
}
