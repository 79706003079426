/* eslint-disable no-param-reassign */
import isEmpty from "lodash/isEmpty";
import {
  handleTriggerToolTip,
  removeFixedTooltip,
  FILTER_QUERY_PARAM_NAME,
} from "../chart";
import { appendQueryParam, getQueryParam, removeQueryParam } from "../helpers";

const HIGHLIGHTED_FILTER = [
  "text-gray-800",
  "font-semibold",
  "border-b-2",
  "border-gray-800",
];

class FilterController {
  constructor({
    chart,
    xAxisPoints,
    dataSetsBgColorValue,
    dataSetsInactiveHourBgColorValue,
    hoverBgColorValue,
    filterBtnTargets,
    chartData,
    hasFilterBtnTarget,
    disabledBgColorValue,
  }) {
    this.chart = chart;
    this.xAxisPoints = xAxisPoints;
    this.dataSetsBgColorValue = dataSetsBgColorValue;
    this.dataSetsInactiveHourBgColorValue = dataSetsInactiveHourBgColorValue;
    this.hoverBgColorValue = hoverBgColorValue;
    this.filterBtnTargets = filterBtnTargets;
    this.chartData = chartData;
    this.mappedSetAndColors = [];
    this.initialDataSetOrder = [];
    this.hasFilterBtnTarget = hasFilterBtnTarget;
    this.disabledBgColorValue = disabledBgColorValue;

    if (this.chart.data.datasets.length > 1) {
      this.mappedSetAndColors = this.chartData.datasets.map((el) => ({
        backgroundColor: el.backgroundColor,
        hoverBackgroundColor: el.hoverBackgroundColor,
        attribute: el.label.toLowerCase(),
      }));

      this.initialDataSetOrder = this.chartData.datasets.map((el) => el.label.toLowerCase());
    }
  }

  handleFilter({ attribute, currentTarget }) {
    const resetFilter = isEmpty(attribute);
    this.handleQueryParams({ resetFilter, attribute });

    if (resetFilter) {
      this.chart.data.datasets.map((el, idx) => {
        el.backgroundColor = this.mappedSetAndColors[idx].backgroundColor;
        el.hoverBackgroundColor = this.mappedSetAndColors[idx].hoverBackgroundColor;
        return el;
      });
    } else {
      this.handleBarsBackgroundChange({ attribute });
    }

    this.handleDataSetOrder({ attribute, order: !resetFilter });

    removeFixedTooltip(this.chart);

    handleTriggerToolTip({
      xAxisPoints: this.xAxisPoints,
      chart: this.chart,
    });

    this.chart.update();

    if (currentTarget) {
      this.handleSelectedToggle(currentTarget);
    }
  }

  handleQueryParams({ resetFilter, attribute }) {
    if (resetFilter) {
      removeQueryParam({ paramElement: FILTER_QUERY_PARAM_NAME });
    } else {
      appendQueryParam({
        paramElement: FILTER_QUERY_PARAM_NAME,
        paramValue: attribute,
      });
    }
  }

  handleBarsBackgroundChange({ attribute }) {
    const selectedAttributeColors = this.mappedSetAndColors.filter(
      (el) => el.attribute.toLowerCase() === attribute.toLowerCase(),
    );

    const found = !isEmpty(selectedAttributeColors);

    this.chart.data.datasets.map((el) => {
      const selectedSet = el.label.toLowerCase() === attribute.toLowerCase();

      el.backgroundColor = selectedSet && found
        ? selectedAttributeColors[0].backgroundColor
        : Array(el.data.length).fill(this.disabledBgColorValue);
      el.hoverBackgroundColor = selectedSet && found
        ? selectedAttributeColors[0].hoverBackgroundColor
        : Array(el.data.length).fill(this.disabledBgColorValue);
      return el;
    });
  }

  handleDataSetOrder({ attribute, order }) {
    const target = this.chart.data.datasets.find(
      (obj) => obj.label.toLowerCase() === attribute.toLowerCase(),
    );
    if (order) {
      this.chart.data.datasets.splice(
        this.chart.data.datasets.indexOf(target),
        1,
      );
      this.chart.data.datasets.unshift(target);
    } else {
      this.chart.data.datasets.sort(
        (a, b) => this.initialDataSetOrder.indexOf(a.label.toLowerCase())
          - this.initialDataSetOrder.indexOf(b.label.toLowerCase()),
      );
    }
  }

  handleSelectedToggle(currentTarget) {
    this.filterBtnTargets.forEach((element) => {
      element.classList.remove(...HIGHLIGHTED_FILTER);
    });
    currentTarget.classList.add(...HIGHLIGHTED_FILTER);
  }

  handleOnLoadFilter() {
    const selected = this.filterBtnTargets.filter(
      (el) => el.dataset.insightsAttributeParam === this.filteredValue,
    );

    if (this.hasFilterBtnTarget) {
      this.handleSelectedToggle(
        isEmpty(selected) ? this.filterBtnTargets[0] : selected[0],
      );
    }

    if (!isEmpty(this.filteredValue) && this.chart.data.datasets.length > 1) {
      this.handleFilter({
        attribute: this.filteredValue,
      });
    }
  }

  get filteredValue() {
    return getQueryParam({ paramElement: FILTER_QUERY_PARAM_NAME });
  }
}

export default FilterController;
