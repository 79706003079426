import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  copyToClipboard(element) {
    const { target } = element;
    const tooltip = target.parentElement.querySelector(".fl-copy__tooltip");

    const range = document.createRange();
    range.selectNode(target);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();

    tooltip.classList.add("fl-is-visible");
    setTimeout(() => {
      tooltip.classList.remove("fl-is-visible");
    }, 1000);
  }
}
