import { Controller } from "@hotwired/stimulus";
import { appendQueryParam } from "./helpers";

export default class extends Controller {
  static targets = ["menu"];

  toogleMenu() {
    this.menuTarget.classList.toggle("is-open");
  }

  changeLocale({ params: { locale } }) {
    this.toogleMenu();
    appendQueryParam({
      paramElement: "locale",
      paramValue: locale,
    });
    window.location.reload();
  }

  closeBackground(e) {
    if (e && !this.menuTarget.contains(e.target)) {
      this.menuTarget.classList.remove("is-open");
    }
  }
}
