import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item"];

  initialize() {
  }

  connect() {
    this.sortItemsByTimestamp();
  }

  disconnect() {
  }

  sortItemsByTimestamp() {
    // Check if any item is currently being removed
    if (this.isAnyItemBeingRemoved()) {
      return; // Skip reordering
    }

    const items = this.itemTargets;
    const sortedItems = [...items].sort((a, b) => {
      const aTimestamp = parseInt(a.dataset.sortingTimestamp, 10);
      const bTimestamp = parseInt(b.dataset.sortingTimestamp, 10);
      return aTimestamp - bTimestamp;
    });

    sortedItems.forEach(item => {
      item.parentNode.appendChild(item);
    });
  }

  itemTargetConnected() {
    this.sortItemsByTimestamp();
  }

  complete(event) {
    event.target.classList.add("completed")
  }

  triggerRemove(event) {
    event.target.classList.add("removing")
  }

  handleAnimationEnd(event) {
    if(event.animationName === "shrink") {
      this.triggerRemove(event)
    }
    if(event.animationName === "fadeOut") {
      event.target.remove()
    }

    // Trigger reordering after animation ends
    this.sortItemsByTimestamp();
  }

  isAnyItemBeingRemoved() {
    return this.itemTargets.some(item => item.classList.contains('removing'));
  }

  setActiveAndFollow(event){

    this.itemTargets.forEach((item) =>{
      item.classList.remove('is-active');
    })
    event.currentTarget.classList.add("is-active")
    window.location.href = event.currentTarget.querySelector("a").href;
  }
}
