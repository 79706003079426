/* eslint-disable no-param-reassign */
import { isSameDateTime } from "../chart";

class InsightsChart {
  constructor({
    dataSetsBgColorValue,
    dataSetsInactiveHourBgColorValue,
    hoverBgColorValue,
    activeHourBgColorValue,
    inactiveHourBgColorValue,
    incomingHourBgColorValue,
    dataValue,
  }) {
    this.dataSetsBgColorValue = dataSetsBgColorValue;
    this.dataSetsInactiveHourBgColorValue = dataSetsInactiveHourBgColorValue;
    this.hoverBgColorValue = hoverBgColorValue;
    this.activeHourBgColorValue = activeHourBgColorValue;
    this.inactiveHourBgColorValue = inactiveHourBgColorValue;
    this.incomingHourBgColorValue = incomingHourBgColorValue;
    this.dataValue = dataValue;
  }

  populateGraphData() {
    const isMultipleSets = this.dataValue.datasets.length > 1;
    const { labels } = this.dataValue;

    this.dataValue.datasets = this.dataValue.datasets.map((el, idx) => ({
      ...el,
      backgroundColor: isMultipleSets
        ? el.data.map((set, datasetIndex) => this.handleBackgroundColor({
          date: new Date(labels[datasetIndex]),
          activeColor: this.dataSetsBgColorValue[idx],
          incomingColor: this.dataSetsBgColorValue[idx],
          inactiveColor: this.dataSetsInactiveHourBgColorValue[idx],
        }))
        : el.data.map((set) => this.handleBackgroundColor({
          date: new Date(set.x),
          activeColor: this.activeHourBgColorValue,
          inactiveColor: this.inactiveHourBgColorValue,
          incomingColor: this.incomingHourBgColorValue,
        })),
      hoverBackgroundColor: isMultipleSets
        ? this.hoverBgColorValue
        : el.data.map((set) => this.handleHoverBackgroundColor({
          date: new Date(set.x),
          activeColor: this.activeHourBgColorValue,
          hoverColor: this.hoverBgColorValue,
        })),
    }));

    return this.dataValue;
  }

  handleBackgroundColor({
    date, activeColor, inactiveColor, incomingColor,
  }) {
    const currentDate = new Date();

    if (isSameDateTime(date, currentDate)) {
      return activeColor;
    }
    if (date < currentDate) {
      return inactiveColor;
    }
    return incomingColor;
  }

  handleHoverBackgroundColor({ date, activeColor, hoverColor }) {
    const currentDate = new Date();

    if (isSameDateTime(date, currentDate)) {
      return activeColor;
    }
    return hoverColor;
  }
}

export default InsightsChart;
