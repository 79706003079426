import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["ride"];

  initialize() {
    this.loaded = false;
    this.willUnload = false;
  }

  connect() {
    this.loaded = true;
  }

  disconnect() {
    this.willUnload = true;
  }

  rideTargetConnected(_target) {
    this.updateRidesCounter();
  }

  rideTargetDisconnected(_target) {
    this.updateRidesCounter();
  }

  updateRidesCounter() {
    if (!this.loaded) return;
    if (this.willUnload) return;

    const count = this.rideTargets.length;
    const activeRidesCounterElements = document.getElementsByClassName("counter");
    if (activeRidesCounterElements) {
      Array.prototype.forEach.call(activeRidesCounterElements, function(element) {
        let text = element.textContent || element.innerText;
        text = text.replace(/\d+/, count);
        element.textContent = text;
      });
    }
  }
}
