import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    zonesWithCenter: Object,
  }

  static targets = ["map"]

  connect() {
    this.priceTags = {}

    this.element["mapController"] = this
    if (typeof google != "undefined") {
      this.initializeMap();
    }
  }

  initializeMap() {
    const osloCenter = {lat: 59.926356831354106, lng: 10.774354276892957}
    this.map = new google.maps.Map(this.mapTarget, {
      zoom: 10,
      center: osloCenter,
      disableDefaultUI: true,
    });
    this.drawZones()
    this.addListeners()
  }

  drawZones() {
    Object.keys(this.zonesWithCenterValue).forEach((zoneId) => {
      this.map.data.loadGeoJson(`/backoffice/zones/geojson?id=${zoneId}`, {idPropertyName: "Id"}, this.drawPrice(zoneId))
    });
  }

  addListeners() {
    const self = this
    this.map.data.addListener('mouseover', function (event) {
      this.map.data.revertStyle();
      this.map.data.overrideStyle(event.feature, {
        strokeWeight: 6,
        fillColor: "green"
      });
    });

    this.map.data.addListener('mouseout', function () {
      this.map.data.revertStyle();
    });

    this.map.data.addListener('click', function (event) {
      self.hidePriceTables()
      self.showPriceTable(event.feature.getId())
    })
  }

  showPriceTable(zone) {
    let tableElement = document.querySelector(`[data-zone-id="${zone}"]`)
    tableElement.classList.remove("hidden")
  }

  hidePriceTables() {
    let elements = document.querySelectorAll("[data-zone-id]")
    elements.forEach(function (node) {
      node.classList.add("hidden")
    })
  }

  priceToShow(zoneId) {
    const priceGroup = document.getElementById("price-group-select").value;
    return document.querySelector(`.price-to-show[data-zone="${zoneId}"][data-price-group="${priceGroup}"]`)?.value;
  }

  drawPrice(zoneId) {
    const price = this.priceToShow(zoneId);
    const center = this.zonesWithCenterValue[zoneId];
    const background = {
      url: "/price-bg.png",
      scaledSize: new google.maps.Size(64, 24),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(0, 0),
    };
    let markerLabel = {
      text: `${price} kr`,
      fontWeight: "bold"
    }
    this.priceTags[zoneId] = new google.maps.Marker({
      position: center,
      label: markerLabel,
      map: this.map,
      icon: background
    })
  }

  updatePrice(zoneId, newPrice) {
    let priceTag = this.priceTags[zoneId]
    let label = priceTag.getLabel()
    label.text = newPrice ? `${newPrice} kr ` : "missing";
    priceTag.setLabel(label)
  }

  updatePriceTagsWithPriceGroup() {
    Object.keys(this.zonesWithCenterValue).forEach((zoneId) => {
      const newPrice = this.priceToShow(zoneId);
      this.updatePrice(zoneId, newPrice);
    });
  }
}
